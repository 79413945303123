import React from 'react';
import { Modal } from 'antd';
import '../../../styles/admin/SkillsGapAnalysisTabView.module.scss';

type Props = {
  isVisible: boolean,
  onCancel: () => void,
  src: string,
  title: string
}

const VideoModal:React.FC<Props> = ({
  isVisible,
  onCancel,
  src,
  title,
}) => (
  <Modal
    visible={isVisible}
    onCancel={onCancel}
    footer={null}
    className="video-modal-wrapper"
  >
    <iframe
      src={src}
      title={title}
      width="90%"
      height="100%"
      frameBorder="0"
      allowFullScreen
      allow="autoplay"
    />
  </Modal>
);

export default VideoModal;

// extracted by mini-css-extract-plugin
export var skillsAnalysisContainer = "SkillsGapAnalysisTabView-module--skillsAnalysisContainer--BWU0_";
export var analysisReportSection = "SkillsGapAnalysisTabView-module--analysisReportSection--3PQcr";
export var learnerAnalysis = "SkillsGapAnalysisTabView-module--learnerAnalysis--1s3FN";
export var additionalAnalysisInfo = "SkillsGapAnalysisTabView-module--additionalAnalysisInfo--3XPOR";
export var videoCard = "SkillsGapAnalysisTabView-module--videoCard--B0I4h";
export var tableSection = "SkillsGapAnalysisTabView-module--tableSection--2FJ6X";
export var reportReminderContainer = "SkillsGapAnalysisTabView-module--reportReminderContainer--fXSt6";
export var userCountText = "SkillsGapAnalysisTabView-module--userCountText--13tBu";
export var requestAnalysisCta = "SkillsGapAnalysisTabView-module--requestAnalysisCta--vqYtx";
export var resetCta = "SkillsGapAnalysisTabView-module--resetCta--3iOmC";
/* eslint-disable @typescript-eslint/no-non-null-assertion */
/* eslint-disable no-await-in-loop */
import React, {
  useState, useEffect, useContext, useMemo,
} from 'react';
import { Progress } from 'antd';
import { navigate } from 'gatsby';
import { StaticImage } from 'gatsby-plugin-image';
import type { ColumnsType } from 'antd/es/table';
import { API } from 'aws-amplify';
import * as styles from '../../../styles/admin/SkillsGapAnalysisTabView.module.scss';
import AdminContext from '../../../context/Admin/AdminContext';
import TableComponent from '../../Shared/Table/TableComponent';
import { fetchSkillsReport } from '../../../services/ReportAnalysisService';
import VideoModal from './VideoModal';
import useSkillsReportId from '../../../hooks/useSkillsReportId';
import { adminListReportResultsByEnterpriseID } from '../../../custom-graphql/queries';
import { Report, Role, Skill } from '../../../models';
import { LearnersReportResultData } from '../../../types/skillsGapAnalysis';
import RequestAnalysisStatusPopup from './Popups/RequestAnalysisStatusPopup';
import { adminResetReportSubmission } from '../../../graphql/mutations';
import { isBDMUser, toaster } from '../../../services/utils';
import ConfirmPopup from '../Accounts/Popups/ConfirmPopup';

interface ColumnDataType {
  title: string,
  dataIndex: string
}

interface SkillsResult {
  id: string,
  archived: boolean | null,
  userID: string,
  userName: string,
  labels: string[],
  [key:string]: number | string | string[] | boolean | null,
}

interface LearnersReportResult {
  data: {
    results: SkillsResult[],
    report: Report | null
  } | null,
  isLoading: boolean,
  error: any
}

interface Options {
  query: string,
  variables: {
    enterpriseId: string,
    nextToken?: string | null
  }
}

const defaultState = {
  data: null,
  isLoading: true,
  error: null,
};

const VIDEO_URL = 'https://player.vimeo.com/video/781453852?h=ad0e9a3ab3&amp;badge=0&amp;autoplay=1&amp;player_id=0&amp;app_id=58479';

const SkillsGapAnalysisTabView:React.FC = () => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [learnersReportResults, setLearnersReportResults] = useState<
  LearnersReportResult>(defaultState);
  const [isRequestAnalysisModalVisible, setIsRequestAnalysisModalVisible] = useState(false);
  const [
    isResetReportSubmissionModalVisible,
    setIsResetReportSubmissionModalVisible,
  ] = useState(false);
  const [selectedUser, setSelectedUser] = useState<SkillsResult>();
  const [isConfirmModalVisible, setIsConfirmModalVisible] = useState(false);

  const { companyInfo, enterpriseUsersList } = useContext(AdminContext);
  const { reportId } = useSkillsReportId();

  const totalUsers = enterpriseUsersList?.size || 0;
  const usersWithReportSubmission = learnersReportResults?.data
    ?.results?.filter((user: SkillsResult) => !user?.archived) || [];

  const usersWithoutReportSubmission = useMemo(() => {
    const userIdsWithReportSubmission = learnersReportResults?.data?.results.map(
      (currLearner: SkillsResult) => currLearner?.userID,
    );
    const allUsers = enterpriseUsersList ? [...enterpriseUsersList?.values()] : [];
    const learnersYetToTakeAnalysis = allUsers?.filter((user) => {
      if (user?.roles.includes(Role.OWNER)) {
        return false;
      }
      if (userIdsWithReportSubmission?.includes(user?.id)) {
        return false;
      }

      return true;
    });

    return learnersYetToTakeAnalysis;
  }, [enterpriseUsersList, learnersReportResults]);

  const navigateToLearnerAnalysisPage = () => {
    navigate(`/learner/analysis/${reportId}/`);
  };

  const generateColumnData = () => {
    if (learnersReportResults?.data && learnersReportResults.data?.report) {
      const labels = learnersReportResults.data.report.skills.map((skill) => skill.label);
      const columns:ColumnsType<ColumnDataType> = [];
      labels.forEach((label) => {
        columns.push({
          title: label,
          dataIndex: label,
          render(score:string) {
            return (
              <p>{`${score}%`}</p>
            );
          },
          width: 150,
        });
      });
      columns.unshift({
        title: 'Name',
        dataIndex: 'userName',
        width: 130,
      });

      columns.push({
        title: 'Reset Score',
        dataIndex: 'resetScore',
        width: 150,
        render(_, record: any) {
          return (
            <button
              id="admin-performance-skills-gap-analysis-reset-score"
              className={styles.resetCta}
              disabled={record?.archived}
              onClick={() => {
                setSelectedUser(record);
                setIsResetReportSubmissionModalVisible(true);
              }}
              type="button"
            >
              Reset
            </button>
          );
        },
      });
      return columns;
    }
    return [];
  };

  const prepareTableData = (result:LearnersReportResultData, skillsMap:Map<string, Skill>) => {
    let skillResult:SkillsResult = {} as SkillsResult;
    const labels:string[] = [];
    result.skillScores.forEach((skill, index) => {
      const skillId = skill?.skillID;
      const skillInfo = skillsMap.get(skillId);
      const label = skillInfo?.label || '';
      const score = skill?.maxScore ? Math.ceil((skill.score / skill.maxScore) * 100) : 0;
      if (!index) {
        skillResult = {
          id: result?.id || '',
          userID: result?.user?.id || '',
          userName: result?.user?.name || '',
          labels: [],
          archived: result?.archived,
        };
      }
      labels.push(label);
      skillResult = {
        ...skillResult,
        [label]: score,
      };
    });
    skillResult.labels = labels;
    skillResult.key = result?.id;
    return skillResult;
  };

  const mergeData = async (results:LearnersReportResultData[], report:Report | null) :
   Promise<SkillsResult[]> => {
    const skillsMap:Map<string, Skill> = new Map();
    if (report) {
      report.skills?.forEach((skill) => {
        const skillId = skill?.id;
        skillsMap.set(skillId, skill);
      });
    }
    return results.map((result) => prepareTableData(result, skillsMap));
  };

  const fetchLearnersReportResults = async () => {
    try {
      let nextToken = null;
      const report = await fetchSkillsReport();
      const reportID = report?.id;
      do {
        const options:Options = {
          query: adminListReportResultsByEnterpriseID,
          variables: {
            enterpriseID: companyInfo?.id,
            ...(nextToken && { nextToken }),
            filter: {
              reportID: {
                eq: reportID,
              },
            },
          },
        };
        const response = await API.graphql(options) as any;
        const data = response?.data?.adminListReportResultsByEnterpriseID;
        const results = data?.items?.length > 0
          ? data.items.filter((item:LearnersReportResultData) => !item.deletedAt
           && !isBDMUser(item.user?.email)) : [];
        const learnerLatestResults: any = [];

        results.forEach((reportResult: any) => {
          if (learnerLatestResults?.length === 0) {
            learnerLatestResults.push(reportResult);
          } else {
            const learnerIndex = learnerLatestResults.findIndex(
              (result: any) => result?.user?.id === reportResult.user?.id,
            );

            if (learnerIndex !== -1) {
              const currentReport = learnerLatestResults[learnerIndex];
              if (new Date(reportResult.updatedAt) > new Date(currentReport?.updatedAt)) {
                learnerLatestResults.splice(learnerIndex, 1, reportResult);
              }
            } else {
              learnerLatestResults.push(reportResult);
            }
          }
        });

        const mergedData = await mergeData(learnerLatestResults, report);
        setLearnersReportResults({
          data: {
            results: mergedData,
            report: report || null,
          },
          isLoading: false,
          error: null,
        });
        nextToken = data?.nextToken;
      } while (nextToken);
    } catch (err) {
      setLearnersReportResults({
        data: null,
        isLoading: false,
        error: err,
      });
    }
  };

  const toggleRequestAnalysisModalVisibility = () => {
    setIsConfirmModalVisible(false);
    setIsRequestAnalysisModalVisible((prevState) => !prevState);
  };

  const toggleConfirmModalVisibility = () => {
    setIsConfirmModalVisible((prevState) => !prevState);
  };

  const closeConfirmPopupHandler = () => {
    setIsResetReportSubmissionModalVisible(false);
  };

  const resetScoreHandler = async () => {
    try {
      setLearnersReportResults((prevState) => {
        const allResults = prevState.data!.results;
        const learnerIndex: number = allResults.findIndex(
          (currUser) => currUser.userID === selectedUser?.userID,
        );

        // eslint-disable-next-line no-param-reassign
        prevState!.data!.results[learnerIndex]!.archived = true;
        return ({
          ...prevState,
        });
      });

      setIsResetReportSubmissionModalVisible(false);

      await API.graphql({
        query: adminResetReportSubmission,
        variables: {
          input: {
            enterpriseID: companyInfo?.id,
            userID: selectedUser?.userID,
            reportID: reportId,
          },
        },
      });
    } catch (error) {
      console.log(error);
      toaster('Something went wrong', 'error');
      fetchLearnersReportResults();
    }
  };

  const columns = generateColumnData();

  const headers = columns.filter((column: any) => column?.dataIndex !== 'resetScore').map((header: any) => ({
    label: header.title,
    key: header.dataIndex,
  }));

  const progress = (totalUsers && usersWithReportSubmission?.length > 0)
    ? Math.floor((usersWithReportSubmission?.length / totalUsers) * 100) : 0;

  useEffect(() => {
    if (companyInfo?.id && enterpriseUsersList) {
      fetchLearnersReportResults();
    }
  }, [companyInfo?.id, enterpriseUsersList]);

  return (
    <div className={styles.skillsAnalysisContainer}>
      <section className={styles.analysisReportSection}>
        <div className={styles.learnerAnalysis}>
          <div>
            <h2>Learner analysis</h2>
            <p>
              <span>{totalUsers}</span>
              Total registered learners
            </p>
            <p>
              <span>{usersWithReportSubmission?.length}</span>
              Analysis completed learners
            </p>
          </div>
          <Progress
            type="circle"
            strokeColor="#9570f1"
            trailColor="#e1d2fd"
            strokeWidth={12}
            format={() => `${progress}%`}
            width={100}
            percent={progress}
          />
        </div>
        <div className={styles.additionalAnalysisInfo}>
          <button
            id="admin-performance-skills-gap-analysis-play-video"
            type="button"
            onClick={() => setIsModalVisible(true)}
            className={styles.videoCard}
          >
            <div>
              <p>Find out more about</p>
              <p>Soft-skills gap analysis</p>
            </div>
            <StaticImage
              src="../../../../static/images/admin/video-play-icon.png"
              alt="play"
              objectFit="contain"
              width={32}
              height={32}
              placeholder="blurred"
            />
          </button>
          {reportId
              && (
              <p>
                Conduct your own Soft-skill gap analysis from
                {' '}
                <span
                  id="admin-performance-skills-gap-analysis-navigate-to-skill-gap-here"
                  role="button"
                  aria-hidden
                  onClick={navigateToLearnerAnalysisPage}
                >
                  here
                </span>
                .
              </p>
              )}
        </div>
      </section>
      <div className={styles.tableSection}>
        <h1>Learner Analysis</h1>
        <TableComponent
          globalSearchPlaceholder="Search by name"
          displayColumns={columns}
          allData={learnersReportResults?.data?.results || []}
          tableLoader={learnersReportResults.isLoading}
          headers={headers}
          fileName="Analysis Report.csv"
          customOptions={{
            customStyle: true,
            scrollable: true,
            cta: 'Export CSV',
          }}
          requestAnalysis={
            {
              requestAnalysisComp:
              toggleConfirmModalVisibility,
            }
          }
          usersWithoutReportSubmissionArray={usersWithoutReportSubmission}
          domIDs={{
            Download: 'admin-performance-skills-gap-analysis-export-csv',
            SendReminderRequest: 'admin-performance-skills-gap-analysis-send-reminder-request',
          }}
        />
      </div>
      {isModalVisible
      && (
      <VideoModal
        isVisible={isModalVisible}
        onCancel={() => setIsModalVisible(false)}
        src={VIDEO_URL}
        title="Soft Skills Admin"
      />
      )}
      {
        (usersWithoutReportSubmission?.length > 0)
      && (
      <div className={styles.reportReminderContainer}>
        <p className={styles.userCountText}>{`${usersWithoutReportSubmission?.length} of your Learners have not taken the Skill Gap Analysis yet!`}</p>
        <button
          id="admin-performance-skills-gap-analysis-request-analysis"
          className={styles.requestAnalysisCta}
          onClick={toggleConfirmModalVisibility}
          type="button"
        >
          Request Analysis
        </button>
      </div>
      )
      }
      <RequestAnalysisStatusPopup
        isModalVisible={isRequestAnalysisModalVisible}
        usersWithoutReportSubmission={usersWithoutReportSubmission}
        onCancel={toggleRequestAnalysisModalVisibility}
        reportId={reportId}
      />
      <ConfirmPopup
        onOk={resetScoreHandler}
        onCancel={closeConfirmPopupHandler}
        modalObject={
          {
            isVisible: isResetReportSubmissionModalVisible,
            title: 'Reset Score',
            description: 'Are you sure you would like to reset this Learners score? This action cannot be undone and all previous scores will be removed.',
            buttonText: 'Confirm',
          }
        }
        domIDs={{
          OK: 'admin-performance-skills-gap-analysis-reset-score-popup-confirm',
          Cancel: 'admin-performance-skills-gap-analysis-reset-score-popup-cancel',
        }}
      />
      <ConfirmPopup
        onOk={toggleRequestAnalysisModalVisibility}
        onCancel={toggleConfirmModalVisibility}
        modalObject={
          {
            isVisible: isConfirmModalVisible,
            title: 'Request Learners to Complete Soft Skills Gap Analysis',
            description: 'Are you sure you want to send an email to your Learners to remind them to Complete their Soft Skills Gap Analysis?',
            buttonText: 'Confirm',
          }
        }
        customStyle={{
          title: {
            fontSize: '25px',
          },
        }}
        domIDs={{
          OK: 'admin-performance-skills-gap-analysis-request-analysis-popup-confirm',
          Cancel: 'admin-performance-skills-gap-analysis-request-analysis-popup-cancel',
        }}
      />

    </div>
  );
};

export default SkillsGapAnalysisTabView;

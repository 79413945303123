import { Form, Modal, Spin } from 'antd';
import Progress from 'antd/es/progress';
import React, { useEffect, useState } from 'react';
import FileSaver from 'file-saver';
import { toaster } from '../../../../services/utils';
import * as styles from '../../../../styles/admin/ImportedUserProgress.module.scss';
import { requestReportSubmission } from '../../../GraphQL/Services';
import { EnterpriseUser } from '../../../../types/enterpriseUser';

type Props = {
  isModalVisible: boolean;
  onCancel: () => void;
  usersWithoutReportSubmission: EnterpriseUser[];
  reportId: string
}

type FailedUsers = {
  email: string,
  status: string,
  reason: string
}

enum UploadStatus {
  SUCCESS = 'success',
  FAILURE = 'failure',
  PRISTINE = 'pristine'
}

const RequestAnalysisStatusPopup: React.FC<Props> = ({
  isModalVisible,
  onCancel,
  usersWithoutReportSubmission,
  reportId,
}) => {
  const [statusPercentage, setStatusPercentage] = useState(0);
  const [uploadStatus, setUploadStatus] = useState<UploadStatus>(UploadStatus.PRISTINE);
  const [loader, setLoader] = useState(false);
  const [
    failedUsersList,
    setFailedUsersList,
  ] = useState<FailedUsers[]>([]);

  const assignCourseToUserList = async () => {
    setLoader(true);

    const totalUsers = usersWithoutReportSubmission?.length;
    const percentagePerUser = 100 / totalUsers;

    if (totalUsers) {
      for (let i = 0; i < usersWithoutReportSubmission.length; i += 1) {
        const userDetails = usersWithoutReportSubmission[i];
        try {
          // eslint-disable-next-line no-await-in-loop
          await requestReportSubmission(
            userDetails?.enterpriseID,
            userDetails?.id,
            reportId,
          );
        } catch (error: any) {
          const errorMessage = error?.errors.find((err: any) => err?.message);

          setFailedUsersList((prevState) => ([...prevState, {
            email: userDetails?.email || '',
            status: 'failed',
            reason: errorMessage?.message || 'Something went wrong',
          }]));
        }
        setStatusPercentage((prevState) => Math.round(prevState + percentagePerUser));

        if (i === usersWithoutReportSubmission.length - 1) {
          setStatusPercentage(100);
        }
      }
    }
  };

  const finalStatusCheck = () => {
    if (statusPercentage === 100) {
      if (!failedUsersList.length) {
        setUploadStatus(UploadStatus.SUCCESS);
        setFailedUsersList([]);
        setLoader(false);
      } else {
        setUploadStatus(UploadStatus.FAILURE);
        setLoader(false);
      }
    }
  };

  const jsonToCSV = (csvData: any) => {
    const items = csvData;
    const header = Object.keys(items[0]);
    const csv = [
      header.join(','), // header row first
      ...items.map((row: any) => header.map((fieldName) => (row[fieldName])).join(',')),
    ].join('\r\n');
    return csv;
  };

  const downloadUpdatedCsv = async (failedUsersArray: any) => {
    if (!failedUsersArray.length) {
      toaster('No failed records for download', 'info');
      return;
    }
    const csvData = jsonToCSV(failedUsersArray);
    const updatedCsvFile = new File([csvData], 'Failed records.csv', { type: '.csv' });
    FileSaver.saveAs(updatedCsvFile);
  };

  const closePopup = () => {
    if (loader) {
      const confirmPopupExit = window.confirm('Request in progress. Changes might not be saved. Are you sure you want to quit?');
      if (confirmPopupExit) {
        setStatusPercentage(0);
        setUploadStatus(UploadStatus.PRISTINE);
        setFailedUsersList([]);
        setLoader(false);
        onCancel();
      }
    } else {
      setStatusPercentage(0);
      setUploadStatus(UploadStatus.PRISTINE);
      setFailedUsersList([]);
      setLoader(false);
      onCancel();
    }
  };

  useEffect(() => {
    finalStatusCheck();
  }, [statusPercentage]);

  useEffect(() => {
    if (isModalVisible) {
      assignCourseToUserList();
    }
  }, [isModalVisible]);

  useEffect(() => {
    function confirmExit(event: any) {
      event.preventDefault();
      if (loader) {
        // eslint-disable-next-line no-param-reassign
        event.returnValue = '';
      }
    }
    window.addEventListener('beforeunload', confirmExit);

    return () => {
      window.removeEventListener('beforeunload', confirmExit);
    };
  }, [loader]);

  return (
    <Modal
      visible={isModalVisible}
      onCancel={closePopup}
      footer={null}
      className="wrapper"
      destroyOnClose
    >
      <Form>
        <div className={styles.UserProgressWrapper}>
          <div className={styles.titleWrapper}>
            <p>Status of your Analysis Request</p>
          </div>
          <div className={styles.labelWrapper}>
            <Progress
              percent={statusPercentage}
              status={(uploadStatus === UploadStatus.FAILURE) ? 'exception' : undefined}
            />
            <Spin spinning={loader} />
          </div>

          {(uploadStatus === UploadStatus.SUCCESS)
          && <div className={styles.successfulUpload}>Analysis requested successfully</div>}

          {(uploadStatus === UploadStatus.FAILURE)
          && (
          <div className={styles.failedUpload}>
            Something went wrong. Try after sometime.
          </div>
          )}
          <div className={styles.flexWrapper}>
            <div className={styles.downloadFormat}>
              <span className="library-shaw-icon-new">
                <i className="icon-new-Sheet-2" />
              </span>
              <p>Download failed records</p>
              <button type="button" onClick={() => downloadUpdatedCsv(failedUsersList)} className="library-shaw-icon-new">
                <i className="icon-new-Download-21" />
              </button>
            </div>
          </div>
          <div className={styles.maxUploadNote}>
            <p>
              <b>Note: </b>
              Please do not exit the page while the request is in progress
            </p>
          </div>
        </div>
      </Form>
    </Modal>
  );
};

export default RequestAnalysisStatusPopup;

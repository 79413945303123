import React from 'react';
import { Modal, Spin } from 'antd';
import PropTypes from 'prop-types';
import * as commonPopupStyles from '../../../../styles/admin/PopupCommon.module.scss';
import * as delStyle from '../../../../styles/admin/DeleteUsers.module.scss';

const ConfirmPopup = ({
  onOk, onCancel, modalObject, isConfirmPopupLoading, customStyle, domIDs,
}) => (
  <Modal
    visible={modalObject?.isVisible}
    onOk={onOk}
    onCancel={onCancel}
    width="85%"
    className="wrapper"
    footer={null}
  >
    <div className={delStyle.DeleteUserWrapper}>
      <div className={delStyle.directory}>
        <p style={{ ...customStyle?.title }}>{modalObject?.title}</p>
      </div>
      <div>
        <p className={delStyle.text}>{modalObject?.description}</p>
      </div>
      <div className={delStyle.btnWrapper}>
        <button
          id={domIDs?.Cancel}
          type="button"
          className={
            (modalObject?.buttonText !== 'Deactivate'
            && modalObject?.buttonText !== 'Delete') ? delStyle.deletebtn : commonPopupStyles.confirmButton
}
          onClick={onCancel}
        >
          Cancel
        </button>
        <button
          id={domIDs?.OK}
          type="button"
          className={(modalObject?.buttonText !== 'Deactivate'
          && modalObject?.buttonText !== 'Delete') ? commonPopupStyles.confirmButton : delStyle.deletebtn}
          onClick={onOk}
        >
          {modalObject?.buttonText}
        </button>
        {
            isConfirmPopupLoading
            && <Spin className={commonPopupStyles.spinner} />
          }
      </div>
    </div>
  </Modal>
);
ConfirmPopup.propTypes = {
  onOk: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
  modalObject: PropTypes.objectOf(PropTypes.any).isRequired,
  isConfirmPopupLoading: PropTypes.bool,
  customStyle: PropTypes.objectOf(PropTypes.any),
  domIDs: PropTypes.objectOf({
    OK: PropTypes.string,
    Cancel: PropTypes.string,
  }),
};

ConfirmPopup.defaultProps = {
  isConfirmPopupLoading: false,
  customStyle: {},
  domIDs: {
    OK: '',
    Cancel: '',
  },
};

export default ConfirmPopup;

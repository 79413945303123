import React from 'react';
import * as styles from '../../../styles/admin/Account.module.scss';
import Layout from '../../../components/Admin/Layout';
import Authenticate from '../../../components/Identity/Authenticate';
import performanceTabs from '../../../performanceTabs';
import { AdminProvider } from '../../../context/Admin/AdminContext';
import SkillsGapAnalysisTabView from '../../../components/Admin/Performance/SkillsGapAnalysisTabView';

type Props = {
  identityProviderClient: any
}

const SkillsGapAnalysisPage:React.FC<Props> = ({ identityProviderClient }) => (
  <AdminProvider>
    <div className={styles.accountPageWrapper}>
      <Layout
        title="Performance & Insights"
        Component={SkillsGapAnalysisTabView}
        tabs={performanceTabs}
        activeTab="Skills Gap Analysis"
        identityProviderClient={identityProviderClient}
        moreInfo="<p className='text'>In this section, Admins and Managers can see user performance, skills analysis and overall course performance</p>"
      />
    </div>
  </AdminProvider>
);

export default Authenticate(SkillsGapAnalysisPage);

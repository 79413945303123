import { useEffect, useState } from 'react';
import { fetchSkillsReport } from '../services/ReportAnalysisService';

const useSkillsReportId = () : {
  reportId: string
} => {
  const [reportId, setReportId] = useState('');

  const getSkillsReportId = async () => {
    const response = await fetchSkillsReport();
    if (response) {
      setReportId(response?.id);
    }
  };

  useEffect(() => {
    getSkillsReportId();
  }, []);

  return { reportId };
};

export default useSkillsReportId;

import { ModelPredicate } from '@aws-amplify/datastore';
import { DataStore, SortDirection } from 'aws-amplify';
import { Report, ReportResult, ReportSubmission } from '../models';

const fetchSkillsReport = async () : Promise<Report | null> => {
  const response = await DataStore.query(Report, (
    report: ModelPredicate<Report> | any,
  ) => (report.deletedAt('eq', null)), {
    sort: (s) => s.createdAt(SortDirection.DESCENDING),
  });
  if (response?.length > 0) return response[0];
  return null;
};

const fetchSkillsReportSubmission = async (
  reportId: string | undefined,
  enterpriseId: string,
) : Promise<ReportSubmission | null> => {
  if (reportId) {
    const submissions = await DataStore.query(ReportSubmission, (
      submission: ModelPredicate<ReportSubmission> | any,
    ) => (
      submission.reportID('eq', reportId).enterpriseID('eq', enterpriseId).deletedAt('eq', null)), {
      sort: (s) => s.createdAt(SortDirection.DESCENDING),
    });
    if (submissions?.length > 0) {
      return submissions?.[0];
    }
  }
  return null;
};

const fetchSkillsReportResult = async (
  reportId: string | undefined,
  enterpriseId: string,
) : Promise<ReportResult | null> => {
  if (reportId) {
    const results = await DataStore.query(ReportResult, (
      result: ModelPredicate<ReportResult> | any,
    ) => (
      result.reportID('eq', reportId).enterpriseID('eq', enterpriseId).deletedAt('eq', null)), {
      sort: (s) => s.createdAt(SortDirection.DESCENDING),
    });
    if (results?.length > 0) {
      return results?.[0];
    }
  }
  return null;
};

export {
  fetchSkillsReport,
  fetchSkillsReportSubmission,
  fetchSkillsReportResult,
};
